import { ProductUI } from '../models/product.ui';
import { ProductCodeEnum } from '../utils/data/product-code-enum';
import { ProductNameEnum } from '../utils/data/product-name-enum';

export const productsConfiguration: ProductUI[] = [
  {
    name: ProductNameEnum.ATL,
    path: 'amadeus-time-limits',
    code: ProductCodeEnum.ATL,
    short_description: 'Ticketing deadline for individual PNRs',
    full_description: '',
    url_image: '/assets/image/product/amadeus_time_limit.png',
    icon: 'fa fa-tachometer',
    active: false,
    deployActive: false,
    shadowMode: true,
    helpLink: '/help/ATL/Default.htm'
  },
  {
    name: ProductNameEnum.DPP,
    path: 'duplicate-pnr',
    code: ProductCodeEnum.DPP,
    short_description:
      'Detection and removal of duplicate PNRs: name matching and itinerary conflicts',
    full_description: '',
    url_image: '/assets/image/product/duplicate_pnr.png',
    icon: 'fa fa-tags',
    active: false,
    deployActive: false,
    shadowMode: true
  },
  {
    name: ProductNameEnum.DPS,
    path: 'duplicate-segments',
    code: ProductCodeEnum.DPS,
    short_description:
      'Detection and removal of duplicate, overlap and non-flyable segments',
    full_description: '',
    url_image: '/assets/image/product/duplicate_segment.png',
    icon: 'fa fa-random',
    active: false,
    deployActive: false,
    shadowMode: true,
    helpLink: '/help/DPS/Default.htm'
  },
  {
    name: ProductNameEnum.FSN,
    path: 'fictitious-names',
    code: ProductCodeEnum.FSN,
    short_description: 'Detection and removal of fake and suspicious names',
    full_description: '',
    url_image: '/assets/image/product/fictitious_name.png',
    icon: 'fa fa-id-card-o',
    active: false,
    deployActive: false,
    shadowMode: true,
    helpLink: '/help/FSNM/Default.htm'
  },
  {
    name: ProductNameEnum.GTL,
    path: 'group-time-limits',
    code: ProductCodeEnum.GTL,
    short_description:
      'Contract, Deposit, Name-in, Ticketing deadlines for Group PNRs',
    full_description: '',
    url_image: '/assets/image/product/group_time_limit.png',
    icon: 'fa fa-group',
    active: false,
    deployActive: false,
    shadowMode: true,
    helpLink: '/help/GTL/Default.htm'
  },
  {
    name: ProductNameEnum.HDG,
    path: 'hidden-groups',
    code: ProductCodeEnum.HDG,
    short_description:
      'Detection and removal of individual PNRs booked to circumvent group fares policy',
    full_description: '',
    url_image: '/assets/image/product/hidden_groups.png',
    icon: 'fa fa-random',
    active: false,
    deployActive: false,
    shadowMode: true
  },
  {
    name: ProductNameEnum.NSM,
    path: 'no-show-manager',
    code: ProductCodeEnum.NSM,
    short_description: 'Downline cancellation and E-Ticket Coupon Update Rules',
    full_description: '',
    url_image: '/assets/image/product/no_show.png',
    icon: 'fa fa-plane',
    active: false,
    deployActive: false,
    shadowMode: true,
    helpLink: '/help/NSM/Default.htm'
  },
  {
    name: ProductNameEnum.TTT,
    path: 'time-to-think',
    code: ProductCodeEnum.TTT,
    short_description:
      'Enables travelers to book their flight (and ancillary services) by placing a trip “on hold” and confirming the payment later for an extra fee',
    full_description: '',
    url_image: '/assets/image/product/time_to_think.png',
    icon: 'fa fa-lightbulb-o',
    active: false,
    deployActive: false,
    shadowMode: true
  },
  {
    name: ProductNameEnum.WLT,
    path: 'unproductive-segments-manager',
    code: ProductCodeEnum.WLT,
    short_description:
      'Detection and removal of unproductive segments which will not materialize',
    full_description: '',
    url_image: '/assets/image/product/waitlist.png',
    icon: 'fa fa-hourglass-half',
    active: false,
    deployActive: false,
    shadowMode: true
  },
  {
    name: ProductNameEnum.CHR,
    path: 'churning',
    code: ProductCodeEnum.CHR,
    short_description:
      'Detection of repeated agent "cancel and rebook" then action on such bookings',
    full_description: '',
    url_image: '/assets/image/product/churning.jpg',
    icon: 'fa fa-spinner',
    active: false,
    deployActive: false,
    shadowMode: true
  },
];
